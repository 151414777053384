import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from '@ulule/owl-kit-components/next'
import { tnc, t } from '@ulule/localize'
import { connect } from 'react-redux'

import { loadBadges } from '$redux/actions/badges'
import { userShape } from '$utils/prop-types'

import Content from '../components/Content'
import List from '../components/List'
import * as S from '../styles'

class BadgeView extends React.Component {
  componentDidMount = () => {
    const { awards, owner } = this.props
    if (awards && awards.length <= 0) {
      this.props.loadBadges(owner && owner.id)
    }
  }

  render() {
    const { awards, moreIcon } = this.props
    const badgeCount = awards && awards.length

    return (
      <React.Fragment>
        <S.UserBadgeInfo>
          {badgeCount > 0 && tnc('%(nb)d badge', '%(nb)d badges', badgeCount, { nb: badgeCount })}
        </S.UserBadgeInfo>

        <S.UserBadgeList>
          <List>
            {awards &&
              awards.length > 0 &&
              awards.map((badge, index) => {
                return (
                  <S.UserBadgeListItem key={index}>
                    <Badge
                      key={index}
                      color={badge.badge.color}
                      description={badge.badge.description}
                      href={badge.badge.absolute_url}
                      icon={badge.badge.image.versions.full.url}
                      title={badge.badge.name}
                      total={badge.badge.users_count}
                    />
                  </S.UserBadgeListItem>
                )
              })}
            <S.UserBadgeListItemMore key="more-badge">
              <Badge
                href={`/about/badges`}
                description={t('Discover how to get them all')}
                icon={moreIcon}
                title={t('See all badges')}
              />
            </S.UserBadgeListItemMore>
          </List>
        </S.UserBadgeList>
      </React.Fragment>
    )
  }
}

Badge.propTypes = {
  awards: PropTypes.arrayOf(
    PropTypes.shape({
      awarded_at: PropTypes.string.isRequired,
      badge: PropTypes.shape({
        absolute_url: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.shape({
          versions: PropTypes.shape({
            full: PropTypes.shape({
              height: PropTypes.number,
              url: PropTypes.string.isRequired,
              width: PropTypes.number,
            }),
          }),
        }),
        name: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
        secondary_color: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        users_count: PropTypes.number.isRequired,
      }),
    })
  ),
  loadBadges: PropTypes.func,
  moreIcon: PropTypes.string,
  owner: userShape,
  username: PropTypes.string,
}

const mapDispatchToProps = (dispatch) => ({
  loadBadges(id) {
    dispatch(loadBadges(id))
  },
})

const mapStateToProps = (state) => ({
  awards: state.badges.awards,
})

export default connect(mapStateToProps, mapDispatchToProps)(BadgeView)
