import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import { connect } from 'react-redux'
import { withRouter } from '@owl-nest/react-router-compat'

import { t } from '@ulule/localize'

import { updateAvatar } from '$redux/actions/profile'

import { Sidebar } from './Sidebar'
import { Hover } from './Hover'
import ProfileAvatar from './ProfileAvatar'
import Nav from './Nav'

import { userShape } from '$utils/prop-types'

class Tabs extends React.Component {
  handleAvatar = (event) => {
    const { owner, updateAvatar, isFileSupported } = this.props
    const file = event.target.files[0]
    const isSupported = isFileSupported(file)

    if (isSupported) {
      updateAvatar(owner && owner.id, file)
    }
  }

  render() {
    const { owner, currentUser, links, isOwner, hasBadges, isReachable, adminUrl, lang, hasChannel, ...props } =
      this.props
    const canEdit = isOwner || (currentUser && currentUser.is_staff)

    return (
      <React.Fragment>
        <div className="b-user__nav-content">
          <div className="b-user__nav-container">
            <MediaQuery query="(min-width: 671px)">
              <div className="b-user__nav-infos">
                {canEdit && (
                  <Hover
                    cls="b-user__picture"
                    handleChange={this.handleAvatar}
                    placeHolder={t('Change your profile picture')}
                  >
                    <ProfileAvatar profile={owner && owner.profile} userId={owner && owner.id} />
                  </Hover>
                )}
                {!canEdit && <ProfileAvatar profile={owner && owner.profile} userId={owner && owner.id} />}
              </div>
            </MediaQuery>
            <MediaQuery query="(min-width: 671px)">
              <Nav
                isOwner={isOwner}
                hasBadges={hasBadges}
                profile={owner && owner.profile}
                currentUser={currentUser}
                owner={owner}
                isReachable={isReachable}
                adminUrl={adminUrl}
                lang={lang}
                hasChannel={hasChannel}
              />
            </MediaQuery>
          </div>
        </div>
        <MediaQuery query="(max-width: 670px)">
          <Sidebar
            isOwner={isOwner}
            hasBadges={hasBadges}
            profile={owner && owner.profile}
            currentUser={currentUser}
            owner={owner}
            isReachable={isReachable}
            adminUrl={adminUrl}
            links={links}
            lang={lang}
            canEdit={canEdit}
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 670px)">
          <Nav
            isOwner={isOwner}
            hasBadges={hasBadges}
            profile={owner && owner.profile}
            currentUser={currentUser}
            owner={owner}
            isReachable={isReachable}
            adminUrl={adminUrl}
            lang={lang}
            hasChannel={hasChannel}
          />
        </MediaQuery>
      </React.Fragment>
    )
  }
}

Tabs.propTypes = {
  adminUrl: PropTypes.string,
  currentUser: userShape,
  hasBadges: PropTypes.bool,
  isFileSupported: PropTypes.func,
  isOwner: PropTypes.bool,
  isReachable: PropTypes.bool,
  lang: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      followers_count: PropTypes.number,
      id: PropTypes.number,
      url: PropTypes.string.isRequired,
      views_count: PropTypes.number,
    })
  ),
  owner: userShape,
  updateAvatar: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  updateAvatar(id, file) {
    dispatch(updateAvatar(id, file))
  },
})

const mapStateToProps = (state) => ({
  successAvatar: state.profile.successAvatar,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tabs))
