import { combineReducers } from 'redux'

import { projects } from '$redux/reducers/projects'
import { badges } from '$redux/reducers/badges'
import { profile } from '$redux/reducers/profile'
import user from '$redux/reducers/user'

export default combineReducers({
  projects,
  badges,
  profile,
  user
})
