import { api } from '$utils/apiRequester'

export const LOAD_BADGES_REQUEST = 'LOAD_BADGES_REQUEST'
export const LOAD_BADGES_SUCCESS = 'LOAD_BADGES_SUCCESS'
export const LOAD_BADGES_FAILURE = 'LOAD_BADGES_FAILURE'

export const loadBadges = id => {
  const formData = new FormData()
  formData.append('id', id)

  return {
    types: [LOAD_BADGES_REQUEST, LOAD_BADGES_SUCCESS, LOAD_BADGES_FAILURE],
    promise: () =>
      api('backend').post(`/users/badges/`, {
        body: formData,
        type: 'formData',
        headers: {
          Accept: '*/*',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
  }
}
