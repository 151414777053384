import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'
import { HashRouter as Router } from 'react-router-dom'

import App from './app'

import config from '$utils/config'
import reducers from './reducers'
import { configure } from '$utils/apiRequester'
import { callAPIMiddleware } from '$redux/middleware/callAPI'

const container = document.querySelector('#react-ulule-user')

const renderApp = (store) => {
  const root = ReactDOM.createRoot(container)
  root.render(
    <Provider store={store}>
      <Router>
        <App userId={config.get('user_id')} />
      </Router>
    </Provider>
  )
}

config.set({
  ...window.UFE,
  ...window.CURRENCIES,
  ...window.ASSETS,
  ...window.USER_DETAIL_DATA,
})

configure(config.get('api'))

const initialState = {
  projects: {
    isLoading: false,
    projects: [],
    currentFilter: {
      value: '',
    },
  },
  badges: {
    isLoading: false,
    awards: [],
  },
  profile: {},
  user: {},
}

const store = createStore(reducers, initialState, applyMiddleware(thunk, callAPIMiddleware))

window.addEventListener('js:location:loaded', async (event) => {
  renderApp(store)
})
