import React from 'react'
import PropTypes from 'prop-types'

export const ButtonAction = ({ url, svg, text, action, cls = null }) => (
  <React.Fragment>
    {svg}
    <a
      className={cls}
      href={url}
      onClick={event => {
        event.preventDefault()
        action()
        return false
      }}
    >
      {text}
    </a>
  </React.Fragment>
)

ButtonAction.propTypes = {
  cls: PropTypes.string,
  svg: PropTypes.element,
  text: PropTypes.string,
  action: PropTypes.func,
  url: PropTypes.string
}
