import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router'
import MediaQuery from 'react-responsive'

import { t } from '@ulule/localize'

import { userShape } from '$utils/prop-types'

import LastBackedProjects from '../components/LastBackedProjects'
import { Channels } from '../components/Channels'
import * as S from '../styles'

export const Presentation = ({ profile, currentUser, lang, hasBadges, owner, currentCurrency, rates, channels }) => {
  const isPartner = profile && profile.getLabel && profile.getLabel.name === t('official')
  const isAuthenticated = currentUser && currentUser.is_authenticated
  const hasChannels = channels && channels.length
  const hasPresentation =
    profile && profile.presentation && profile.presentation[lang] !== null && profile.presentation[lang] !== ''
  const hasLastBackedProjects =
    (profile && profile.stats && profile.stats.supported_projects_count > 0) ||
    (profile && profile.stats && profile.stats.created_projects_online_count > 0)

  if (hasPresentation || (channels && channels.length)) {
    return (
      <div>
        <S.UserDescription
          dangerouslySetInnerHTML={{ __html: profile && profile.presentation && profile.presentation[lang] }}
        />

        {hasChannels > 0 && (
          <Channels
            hasChannels={hasChannels}
            hasPresentation={hasPresentation}
            hasLastBackedProjects={hasLastBackedProjects}
            channels={channels}
            lang={lang}
            owner={owner}
          />
        )}
        {profile && profile.stats && profile.stats.supported_projects_count > 0 && isPartner && (
          <React.Fragment>
            <MediaQuery query="(min-width: 671px)">
              <LastBackedProjects
                isAuthenticated={isAuthenticated}
                limit={6}
                owner={owner}
                lang={lang}
                rates={rates}
                isPartner={isPartner}
                currentCurrency={currentCurrency}
              />
            </MediaQuery>
            <MediaQuery query="(max-width: 670px)">
              <LastBackedProjects
                isAuthenticated={isAuthenticated}
                limit={3}
                owner={owner}
                lang={lang}
                rates={rates}
                isPartner={isPartner}
                currentCurrency={currentCurrency}
              />
            </MediaQuery>
          </React.Fragment>
        )}
        {profile && profile.stats && profile.stats.created_projects_online_count > 0 && !isPartner && (
          <React.Fragment>
            <MediaQuery query="(min-width: 671px)">
              <LastBackedProjects
                isAuthenticated={isAuthenticated}
                limit={6}
                owner={owner}
                lang={lang}
                rates={rates}
                isPartner={isPartner}
                currentCurrency={currentCurrency}
              />
            </MediaQuery>
            <MediaQuery query="(max-width: 670px)">
              <LastBackedProjects
                isAuthenticated={isAuthenticated}
                limit={3}
                owner={owner}
                lang={lang}
                rates={rates}
                isPartner={isPartner}
                currentCurrency={currentCurrency}
              />
            </MediaQuery>
          </React.Fragment>
        )}
      </div>
    )
  }

  if (
    profile &&
    profile.stats &&
    profile.stats.created_projects_online_count &&
    profile.stats.created_projects_online_count > 0
  ) {
    return <Navigate to={`/projects/online/`} />
  }

  if (
    profile &&
    profile.stats &&
    profile.stats.supported_projects_count &&
    profile.stats.supported_projects_count > 0
  ) {
    return <Navigate to={`/projects/supported/`} />
  }

  if (hasBadges) {
    return <Navigate to={`/badges/`} />
  }

  return <Navigate to={`/projects/followed`} />
}

Presentation.propTypes = {
  currentCurrency: PropTypes.string.isRequired,
  currentUser: userShape,
  hasBadges: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  owner: userShape,
  profile: userShape,
  rates: PropTypes.object,
}

export default Presentation
