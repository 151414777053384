import React from 'react'
import PropTypes from 'prop-types'
import { Route, Routes } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import { connect } from 'react-redux'
import { withRouter } from '@owl-nest/react-router-compat'

import { t } from '@ulule/localize'
import { FlashMessage, PrimaryButton } from '@ulule/owl-kit-components'

import { getUser, banUser } from '$redux/actions/user'
import Loader from '$components/Loader'
import config from '$utils/config'
import { userShape } from '$utils/prop-types'
import userModel from '$models/user'

import Header from './components/Header'
import Tabs from './components/Tabs'
import Content from './components/Content'
import Project from './views/Project'
import Badge from './views/Badge'
import { Presentation } from './views/Presentation'

import * as S from './styles'

const ProjectView = withRouter(function ProjectView({
  rocketImg,
  lang,
  currentCurrency,
  rates,
  owner,
  username,
  currentUser,
  ...routeProps
}) {
  return (
    <Project
      key={routeProps?.match?.params?.filter}
      rocketImg={rocketImg}
      lang={lang}
      currentCurrency={currentCurrency}
      rates={rates}
      {...routeProps}
      owner={owner}
      username={username}
      currentUser={currentUser}
    />
  )
})

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
    }
  }

  componentDidMount() {
    const { getUser, userId } = this.props
    const currentUser = userModel(config.get('user'))
    const isAuthenticated = currentUser && currentUser.is_authenticated
    const extraFields = ['labels', 'links', 'stats', 'cover', 'channels']

    getUser(userId, isAuthenticated, extraFields)
    window.location.hash = '#/'
  }

  handleErrors = (error) => {
    this.setState({
      error,
    })
  }

  handleCloseError = (event) => {
    event.preventDefault()
    this.setState({ error: null })
  }

  handleClick = (isOwner, owner) => {
    if (isOwner) {
      return (window.location.href = `/users/${owner.id}/settings/`)
    }

    return (window.location.href = `/discussions/compose/${owner.id}`)
  }

  isSupported = (file) => {
    const maxSizeSupported = 5000000
    const errorMaxSizeSupported = '5MB'
    const supportedFiles = ['gif', 'jpeg', 'png', 'jpg']
    const fileSize = file && file.size
    const fileExtension = file && file.name.match(/\.([^.]*$)/) && file.name.match(/\.([^.]*$)/)[1]

    const index = supportedFiles.findIndex((extension) => extension.toUpperCase() === fileExtension.toUpperCase())
    if (index === -1) {
      this.handleErrors({
        level: 'alert',
        message: t('This type of file is not supported. We only support: %(supportedFiles)s.', {
          supportedFiles: supportedFiles.join(', ').toUpperCase(),
        }),
      })

      return false
    }

    if (fileSize > maxSizeSupported) {
      this.handleErrors({
        level: 'alert',
        message: t('The file is too big. We allow files that are under %(maxSize)s', {
          maxSize: errorMaxSizeSupported,
        }),
      })

      return false
    }

    return true
  }

  handleBanUser = (id) => {
    const { banUser } = this.props
    banUser(id)
  }

  render() {
    // everything about users
    const { owner, isBanned } = this.props
    const profile = owner && owner.profile
    const channels = owner && owner.channels
    const currentUser = userModel(config.get('user'))
    const links = owner && owner.links
    const isReachable = owner && owner.is_reachable
    const hasChannel = channels && channels.length > 0

    // utils variables
    const isOwner = (currentUser && currentUser.id) === (owner && owner.id)
    const hasBadges = config.get('has_badges')
    const username = window.location.pathname.replace(/\//g, '')

    // config related variables
    const rates = config.get('RATES')
    const rocketImg = config.get('rocket')
    const moreIcon = config.get('moreIcon')
    const lang = config.get('locale')
    const currentCurrency = config.get('CURRENT')
    const adminUrl = config.get('admin_url') || null

    return (
      <React.Fragment>
        {owner && owner.profile && (
          <div className="b-user">
            {this.state.error && (
              <FlashMessage
                level={this.state.error.level}
                onClickClose={this.handleCloseError}
                translations={{
                  close: t('Close'),
                }}
              >
                {this.state.error.message}
              </FlashMessage>
            )}
            <Header
              isOwner={isOwner}
              owner={owner}
              currentUser={currentUser}
              handleErrors={this.handleErrors}
              isFileSupported={this.isSupported}
              profile={profile}
            />
            <Tabs
              owner={owner}
              links={links}
              isOwner={isOwner}
              currentUser={currentUser}
              hasBadges={hasBadges}
              isReachable={isReachable}
              adminUrl={adminUrl}
              lang={lang}
              isFileSupported={this.isSupported}
              hasChannel={hasChannel}
            />
            <Content
              profile={profile}
              owner={owner}
              isOwner={isOwner}
              currentUser={currentUser}
              hasBadges={hasBadges}
              isReachable={isReachable}
              links={links}
              lang={lang}
              handleBanUser={this.handleBanUser}
              isBanned={isBanned}
            >
              <Routes>
                <Route
                  path="/projects/:filter"
                  element={
                    <ProjectView
                      rocketImg={rocketImg}
                      lang={lang}
                      currentCurrency={currentCurrency}
                      rates={rates}
                      owner={owner}
                      username={username}
                      currentUser={currentUser}
                    />
                  }
                />
                <Route path="/badges/" element={<Badge username={username} owner={owner} moreIcon={moreIcon} />} />
                <Route
                  path="/"
                  element={
                    <Presentation
                      lang={lang}
                      currentCurrency={currentCurrency}
                      rates={rates}
                      owner={owner}
                      hasBadges={hasBadges}
                      profile={profile}
                      username={username}
                      currentUser={currentUser}
                      channels={channels}
                    />
                  }
                />
              </Routes>
            </Content>
            {currentUser && currentUser.is_authenticated && (
              <MediaQuery query="(max-width: 740px)">
                <S.ProfileButton>
                  <PrimaryButton onClick={() => this.handleClick(isOwner, owner)}>
                    {isOwner ? t('Modify my profile') : t('Send a message')}
                  </PrimaryButton>
                </S.ProfileButton>
              </MediaQuery>
            )}
          </div>
        )}
        {!owner && <Loader />}
      </React.Fragment>
    )
  }
}

App.propTypes = {
  getUser: PropTypes.func.isRequired,
  owner: userShape,
  userId: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => ({
  owner: state.user.loadedUser,
  isBanned: state.user.isBanned,
})

const mapDispatchToProps = (dispatch) => ({
  getUser(id, isAuthenticated, extraFields) {
    dispatch(getUser(id, isAuthenticated, extraFields))
  },
  banUser(id) {
    dispatch(banUser(id))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
