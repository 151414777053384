import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { LoadMoreButton } from '@ulule/owl-kit-components'
import { t } from '@ulule/localize'

import ProjectsList from '$components/ProjectsList/ProjectsList'

import { loadProjects, emptyProjects } from '$redux/actions/projects'
import { projectShape } from '$utils/prop-types'

import * as S from '../styles'

class LastBackedProjects extends React.Component {
  componentDidMount() {
    const { loadProjects, projects, currentFilter, emptyProjects, owner, isAuthenticated, isPartner, lang } = this.props

    const projectsStatus = !isPartner ? 'online' : 'supported'

    if (projects && projects.length > 0 && currentFilter !== projectsStatus) {
      emptyProjects()
    }

    loadProjects({ id: owner && owner.id, lang, filter: projectsStatus, sort: null, next: null, isAuthenticated })
  }

  handleClick = () => {
    const projectsStatus = !this.props.isPartner ? 'online' : 'supported'
    window.location.href = `${window.location.href}projects/${projectsStatus}/`
  }

  // this function is a helper to return
  // a certain amount of projects
  getProjects = () => {
    const { projects, limit } = this.props
    return projects.slice(0, limit)
  }

  render() {
    const { projects, rates, lang, currentCurrency, limit, isPartner, isAuthenticated } = this.props

    return (
      <S.UserProjects>
        {projects && projects.length > 0 && (
          <React.Fragment>
            {!isPartner && <S.UserProjectsTitle>{t('Last created projects')}</S.UserProjectsTitle>}
            {isPartner && <S.UserProjectsTitle>{t('Last supported projects')}</S.UserProjectsTitle>}
            <ProjectsList
              projects={this.getProjects()}
              currentLanguage={lang}
              currentCurrency={currentCurrency}
              isAuthenticated={isAuthenticated}
              rates={rates}
            />
          </React.Fragment>
        )}
        {projects && projects.length > limit && (
          <S.ProjectsLoadMore>
            <LoadMoreButton
              loading={false}
              onClick={this.handleClick}
              translations={{
                initialLabel: t('See all'),
                errorLabel: ''
              }}
            />
          </S.ProjectsLoadMore>
        )}
      </S.UserProjects>
    )
  }
}

LastBackedProjects.propTypes = {
  currentCurrency: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  isPartner: PropTypes.bool,
  lang: PropTypes.string,
  limit: PropTypes.number,
  projects: PropTypes.arrayOf(projectShape),
  rates: PropTypes.object
}

const mapStateToProps = state => ({
  projects: state.projects.projects,
  currentFilter: state.projects.currentFilter
})

const mapDispatchToProps = dispatch => ({
  loadProjects({ id, lang, filter, sort, next, isAuthenticated }) {
    dispatch(loadProjects({ id, lang, filter, sort, next, isAuthenticated }))
  },
  emptyProjects() {
    dispatch(emptyProjects())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LastBackedProjects)
