import React from 'react'
import PropTypes from 'prop-types'

import { tc } from '@ulule/localize'
import { Icon } from '@ulule/owl-kit-components'

import { distanceInWordsToNow } from '$utils/date'
import { userShape } from '$utils/prop-types'

import * as S from '../styles'

export const Location = ({ profile, owner }) => (
  <S.UserLocation>
    {profile && profile.location && (
      <S.UserLocationContainer>
        <Icon glyph="cursor-map" size={13} />
        <S.UserLocationCity>{profile && profile.location}</S.UserLocationCity>
      </S.UserLocationContainer>
    )}
    {/* need to put @ with screen name s*/}
    <S.UserLocationDate>
      {tc('Registered %(duration)s ago', { duration: distanceInWordsToNow(+new Date(owner && owner.date_joined)) })}
    </S.UserLocationDate>
  </S.UserLocation>
)

Location.propTypes = {
  owner: userShape,
  profile: userShape
}
