import styled from 'styled-components'

import { COLORS, RegularText, Title2 } from '@ulule/owl-kit-components'

export const ChannelWrapper = styled.div`
  margin-bottom: ${props => (props.hasLastBackedProjects ? '50px' : '0')};
`

export const ChannelTitle = styled(RegularText)`
  font-size: 18px;
  font-weight: 500;
  margin-top: ${props => (props.hasPresentation ? '60px' : '0')};
  display: block;
`

export const ChannelList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
`

export const ChannelItem = styled.li`
  padding: 15px 0;
`

export const UserBadgeInfo = styled.p`
  font-weight: 500;
  min-height: 26px;
  margin-bottom: 15px;
  line-height: 0;
`

export const UserBadgeList = styled.div`
  > ul {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin: -10px -10px 20px;
    justify-content: flex-start;
  }
`

export const UserBadgeListItem = styled.li`
  padding: 10px;
  flex: 0 1 auto;
  max-width: 320px;
  min-width: 250px;
  width: 33.33%;

  @media (max-width: 1124px) {
    flex: 1 1 50%;
    margin: 0 auto;
    max-width: 305px;
  }
`

export const UserBadgeListItemMore = styled(UserBadgeListItem)`
  h2 {
    color: ${COLORS.PRIMARY_BLUE};
  }
`

export const UserDescription = styled.div`
  font-size: 17px;
  line-height: 22px;
  word-wrap: break-word;

  > p {
    margin: 0;
  }
`

export const ProjectsEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px;
`

export const ProjectsLoadMore = styled.div`
  text-align: center;
`

export const ProfileButton = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  > * {
    width: 100%;
  }
`

export const UserHeader = styled.div`
  position: relative;
  border-bottom: 1px solid #d3d3d3;
`

export const Banner = styled.div`
    position: absolute;
    height: 320px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('${props => props.cover}');

    @media (max-width: 670px) {
      height: 140px;
    }
`

export const UserInfromation = styled.div`
  @media (max-width: 670px) {
    text-align: inherit;
    flex: none;
    align-items: baseline;
    padding-left: 10px;
    width: 80%;
  }
`

export const UserInformationName = styled.p`
  color: ${COLORS.PRIMARY_BLACK};
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  margin: 0;
`

export const UserLocation = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  align-items: center;

  @media (max-width: 670px) {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    margin-top: 0;
  }
`

export const UserLocationContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: ${COLORS.GREY_SHADE_4};
    height: 13px;
    margin-top: 1px;
    margin-right: 3px;

    @media (max-width: 670px) {
      position: relative;
      top: -3px;
    }
  }

  p {
    margin-left: 2px;
  }
`

export const UserLocationCity = styled.p`
  color: ${COLORS.GREY_SHADE_2};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;

  @media (max-width: 670px) {
    margin-right: 20px;
  }
`

export const UserLocationDate = styled.span`
  color: ${COLORS.GREY_SHADE_3};
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;

  @media (max-width: 670px) {
    flex: none;
  }
`

export const UserProjects = styled.div`
  height: 100%;
`

export const UserProjectsTitle = styled(Title2)`
  margin: 40px 0 15px;
`
