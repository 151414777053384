import React from 'react'
import PropTypes from 'prop-types'

import { t } from '@ulule/localize'

import { ChannelCard } from '@ulule/owl-kit-components/next'
import { getFullName } from '$models/user'
import { getResourceUrl } from '$models/resources'
import { userShape } from '$utils/prop-types'

import * as S from '../styles'

const getRightLanguage = (element, lang) => {
  if (lang && element && element[lang]) {
    return element[lang]
  }

  if (element && element['en']) {
    return element['en']
  }

  return Object.values(element).find((el) => el !== '')
}

export const Channels = ({ channels, hasChannels, hasPresentation, hasLastBackedProjects, lang, owner }) => (
  <S.ChannelWrapper hasLastBackedProjects={hasLastBackedProjects}>
    <S.ChannelTitle hasPresentation={hasPresentation}>
      {hasChannels > 1 ? t('Discover') : t('Discover the channel')}
    </S.ChannelTitle>
    <S.ChannelList>
      {channels.map((channel, index) => {
        const isPartner = channel && channel.partner && channel.partner.id
        const description = isPartner
          ? t('With the support of %(name)s', { name: getFullName(owner, false) })
          : t('Curated by %(name)s', { name: getFullName(owner, true) })

        return (
          <S.ChannelItem key={index}>
            <ChannelCard
              avatar={getResourceUrl(owner && owner.avatar, '40x40')}
              description={description}
              href={channel && channel.absolute_url}
              src={getResourceUrl(channel && channel.cta_background, 'full')}
              title={getRightLanguage(channel && channel.name, lang)}
            />
          </S.ChannelItem>
        )
      })}
    </S.ChannelList>
  </S.ChannelWrapper>
)

Channels.propTypes = {
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      absolute_url: PropTypes.string,
      background: PropTypes.shape({
        value: PropTypes.string,
        versions: PropTypes.shape({
          full: PropTypes.shape({
            height: PropTypes.number,
            url: PropTypes.string,
            width: PropTypes.number,
          }),
        }),
      }),
      bg_color: PropTypes.string,
      created_at: PropTypes.string,
      cta_background: PropTypes.shape({
        value: PropTypes.string,
        versions: PropTypes.shape({
          full: PropTypes.shape({
            height: PropTypes.number,
            url: PropTypes.string,
            width: PropTypes.number,
          }),
        }),
      }),
      description: PropTypes.shape({
        ca: PropTypes.string,
        de: PropTypes.string,
        en: PropTypes.string,
        es: PropTypes.string,
        fr: PropTypes.string,
        it: PropTypes.string,
        nl: PropTypes.string,
        pt: PropTypes.string,
      }),
      id: PropTypes.number,
      name: PropTypes.shape({
        ca: PropTypes.string,
        de: PropTypes.string,
        en: PropTypes.string,
        es: PropTypes.string,
        fr: PropTypes.string,
        it: PropTypes.string,
        nl: PropTypes.string,
        pt: PropTypes.string,
      }),
      position: PropTypes.number,
      slug: PropTypes.string,
      updated_at: PropTypes.string,
    })
  ),
  hasPresentation: PropTypes.bool.isRequired,
  hasLastBackedProjects: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  owner: userShape,
}
