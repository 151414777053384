import React from 'react'
import PropTypes from 'prop-types'

export const Hover = ({ children, cls, placeHolder, handleChange }) => (
  <div className={cls}>
    <input type="file" name="photo" id={cls} onChange={handleChange} />
    <div className={`${cls}--no-hover`}>{children}</div>
    <div className={`${cls}--hover`}>
      <label htmlFor={cls} className="b-user__overlay">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 56">
          <path
            fill="#fff"
            d="M60.18 8.67H49.61L44.39.89A2 2 0 0 0 42.73 0H25.27a2 2 0 0 0-1.66.89l-5.22 7.78H7.82A7.81 7.81 0 0 0 0 16.44v31.78A7.81 7.81 0 0 0 7.82 56h52.36A7.81 7.81 0 0 0 68 48.22V16.44a7.81 7.81 0 0 0-7.82-7.77zM64 48.22A3.8 3.8 0 0 1 60.18 52H7.82A3.8 3.8 0 0 1 4 48.22V16.44a3.8 3.8 0 0 1 3.82-3.77h11.63a2 2 0 0 0 1.67-.89L26.34 4h15.32l5.23 7.78a2 2 0 0 0 1.66.89h11.63A3.8 3.8 0 0 1 64 16.44z"
          />
          <path
            fill="#fff"
            d="M35.05 17.68c-7.76 0-14.07 6-14.07 13.44s6.31 13.44 14.07 13.44 14.07-6 14.07-13.44-6.32-13.44-14.07-13.44zm0 22.88C29.5 40.56 25 36.33 25 31.12s4.52-9.44 10.07-9.44 10.07 4.24 10.07 9.44-4.54 9.44-10.09 9.44z"
          />
        </svg>
        <span>{placeHolder}</span>
      </label>
    </div>
  </div>
)

Hover.propTypes = {
  children: PropTypes.object,
  cls: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  placeHolder: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}
