import React from 'react'
import PropTypes from 'prop-types'

export const Button = ({ url, svg, text, cls }) => (
  <React.Fragment>
    {svg}
    <a className={cls} href={url}>
      {text}
    </a>
  </React.Fragment>
)

Button.propTypes = {
  cls: PropTypes.string,
  svg: PropTypes.object,
  text: PropTypes.string,
  url: PropTypes.string
}
