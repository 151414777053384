import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'

import { userShape } from '$utils/prop-types'

import { Sidebar } from './Sidebar'

export default class Content extends React.Component {
  render() {
    const { owner, links, lang } = this.props
    return (
      <div className="b-user__content">
        <div className="b-user__container">
          <MediaQuery query="(min-width: 671px)">
            <Sidebar owner={owner} lang={lang} links={links} {...this.props} />
          </MediaQuery>
          <div className="b-user__main">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

Content.propTypes = {
  children: PropTypes.object,
  currentUser: userShape,
  hasBadges: PropTypes.bool,
  isOwner: PropTypes.bool.isRequired,
  isReachable: PropTypes.bool,
  lang: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      followers_count: PropTypes.number,
      id: PropTypes.number,
      url: PropTypes.string.isRequired,
      views_count: PropTypes.number,
    })
  ),
  owner: userShape,
  profile: userShape,
  modalIsOpen: PropTypes.bool,
  setModalIsOpen: PropTypes.func,
  handleBanUser: PropTypes.func,
  isBanned: PropTypes.bool,
}
