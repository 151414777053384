import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { LoadMoreButton, SecondaryButton } from '@ulule/owl-kit-components'
import { t } from '@ulule/localize'

import ProjectsList from '$components/ProjectsList/ProjectsList'

import { loadProjects, emptyProjects } from '$redux/actions/projects'
import { projectShape, userShape } from '$utils/prop-types'

import * as S from '../styles'

const filterToSort = {
  online: 'created_at',
  supported: 'supported_at',
  followed: 'followed_at',
}

class Project extends React.Component {
  constructor(props) {
    super(props)
    const currentFilter = props.match && props.match.params && props.match.params.filter

    this.state = {
      currentFilter: currentFilter || '',
    }
  }

  componentDidMount() {
    const { projects, owner, loadProjects, match, emptyProjects, currentUser, lang, isFetchingMore } = this.props
    const isAuthenticated = currentUser && currentUser.is_authenticated
    const currentFilter = match && match.params && match.params.filter
    this.setState({
      currentFilter,
    })

    //TODO: really find why this view is loaded twice and correctly fix the issue
    if (!isFetchingMore) {
      loadProjects({
        id: owner && owner.id,
        lang,
        filter: currentFilter,
        sort: filterToSort[currentFilter],
        next: null,
        isAuthenticated,
      })
    }

    if (projects && projects.length > 0) {
      emptyProjects()
    }
  }

  handleClick = () => {
    const { loadProjects, owner, next, currentUser, lang } = this.props
    const { currentFilter } = this.state
    const isAuthenticated = currentUser && currentUser.is_authenticated

    loadProjects({
      id: owner && owner.id,
      lang,
      filter: currentFilter,
      sort: filterToSort[currentFilter],
      next,
      isAuthenticated,
    })
  }

  render() {
    const { rates, next, isFetchingMore, rocketImg, lang, currentCurrency, projects, currentFilter, currentUser } =
      this.props

    const isAuthenticated = currentUser && currentUser.is_authenticated

    return (
      <React.Fragment>
        {projects && projects.length > 0 && (
          <ProjectsList
            projects={projects}
            currentLanguage={lang}
            currentCurrency={currentCurrency}
            isAuthenticated={isAuthenticated}
            rates={rates}
          />
        )}
        {projects && projects.length <= 0 && !isFetchingMore && (
          <S.ProjectsEmpty>
            <img src={rocketImg.one} srcSet={`${rocketImg.two} 2x, ${rocketImg.three} 3x`} />
            <p>{t('To discover new projects here you go:')}</p>
            <SecondaryButton as="a" href="/discover">
              {t('Discover new projects')}
            </SecondaryButton>
          </S.ProjectsEmpty>
        )}
        {next && (
          <S.ProjectsLoadMore>
            <LoadMoreButton
              loading={isFetchingMore}
              onClick={this.handleClick}
              translations={{
                initialLabel: t('More projects'),
                errorLabel: t('Retry'),
              }}
            />
          </S.ProjectsLoadMore>
        )}
      </React.Fragment>
    )
  }
}

Project.propTypes = {
  currentCurrency: PropTypes.string.isRequired,
  currentUser: userShape,
  isFetchingMore: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  next: PropTypes.string,
  projects: PropTypes.arrayOf(projectShape),
  rates: PropTypes.object,
  rocketImg: PropTypes.shape({
    one: PropTypes.string.isRequired,
    two: PropTypes.string.isRequired,
    three: PropTypes.string.isRequired,
  }),
}

const mapStateToProps = (state) => ({
  isFetchingMore: state.projects.isLoading,
  projects: state.projects.projects,
  filters: state.projects.filters,
  next: state.projects.next,
  currentFilter: state.projects.currentFilter,
})

const mapDispatchToProps = (dispatch) => ({
  loadProjects({ id, lang, filter, sort, next, isAuthenticated }) {
    const extraFields = 'partnerships'
    dispatch(loadProjects({ id, extraFields, lang, filter, sort, next, isAuthenticated }))
  },
  emptyProjects() {
    dispatch(emptyProjects())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Project)
