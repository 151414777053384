import React from 'react'
import PropTypes from 'prop-types'
import { t, tnc } from '@ulule/localize'
import { NavLink } from 'react-router-dom'
import { Tabs } from '@ulule/owl-kit-components'
import MediaQuery from 'react-responsive'

import { projectShape } from '$utils/prop-types'
import * as S from './styles'
import { Button } from './Button'

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      availableRoutes: []
    }
  }

  componentDidMount() {
    this.setAvailableRoutes()
  }

  setAvailableRoutes = () => {
    const { profile, hasBadges, lang, hasChannel } = this.props
    const presentation = profile && profile.presentation
    const created_projects_online_count = profile && profile.stats.created_projects_online_count
    const supported_projects_count = profile && profile.stats.supported_projects_count
    const followed_projects_count = profile && profile.stats.followed_projects_count

    let availableRoutes = []

    if ((presentation && presentation[lang] !== '') || hasChannel) {
      availableRoutes.push({
        pathName: '/',
        name: t('Presentation'),
        value: ''
      })
    }

    if (created_projects_online_count && created_projects_online_count > 0) {
      availableRoutes.push({
        pathName: '/projects/online/',
        name: tnc('[span: %(nb)d] Created project', '[span: %(nb)s] Created projects', created_projects_online_count, {
          nb: created_projects_online_count,
          span: <S.SubtleText>{created_projects_online_count}</S.SubtleText>
        })
      })
    }

    if (supported_projects_count && supported_projects_count > 0) {
      availableRoutes.push({
        pathName: '/projects/supported/',
        name: tnc('[span: %(nb)d] Backed projects', '[span: %(nb)s] Backed projects', supported_projects_count, {
          nb: supported_projects_count,
          span: <S.SubtleText>{supported_projects_count}</S.SubtleText>
        })
      })
    }

    if (followed_projects_count && followed_projects_count > 0) {
      availableRoutes.push({
        pathName: '/projects/followed/',
        name: tnc('[span: %(nb)d] Listed projects', '[span: %(nb)s] Listed projects', followed_projects_count, {
          nb: followed_projects_count,
          span: <S.SubtleText>{followed_projects_count}</S.SubtleText>
        })
      })
    }

    if (hasBadges) {
      availableRoutes.push({
        pathName: '/badges/',
        name: t('Badges')
      })
    }

    this.setState({
      availableRoutes
    })
  }

  render() {
    const { currentUser, adminUrl } = this.props
    const { availableRoutes } = this.state

    return (
      <div className="b-user__nav-main">
        <S.TabsContainer>
          {availableRoutes && availableRoutes.length > 0 && (
            <React.Fragment>
              <MediaQuery query="(min-width: 671px)">
                <Tabs>
                  {availableRoutes.map((route, index) => {
                    return (
                      <NavLink
                        activeClassName="b-user__tabs--active"
                        exact
                        key={index}
                        to={{ pathname: route.pathName }}
                      >
                        {route.name}
                      </NavLink>
                    )
                  })}
                </Tabs>
              </MediaQuery>
              <MediaQuery query="(max-width: 670px)">
                <Tabs>
                  {availableRoutes.map((route, index) => {
                    return (
                      <NavLink
                        activeClassName="b-user__tabs--active"
                        exact
                        key={index}
                        to={{ pathname: route.pathName }}
                      >
                        {route.name}
                      </NavLink>
                    )
                  })}
                </Tabs>
              </MediaQuery>
            </React.Fragment>
          )}
        </S.TabsContainer>

        {currentUser && currentUser.is_authenticated && currentUser.is_staff && !!adminUrl && (
          <MediaQuery query="(min-width: 671px)">
            <S.AdminButton>
              <Button text="😎" cls="b-user__admin" url={adminUrl} />
            </S.AdminButton>
          </MediaQuery>
        )}
      </div>
    )
  }
}
Nav.propTypes = {
  hasBadges: PropTypes.bool,
  profile: PropTypes.shape({
    created_projects_online_count: PropTypes.number,
    supported_projects_count: PropTypes.number,
    followed_projects_count: PropTypes.number,
    presentation: PropTypes.object
  }),
  projects: projectShape
}
export default Nav
