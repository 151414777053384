import * as ActionTypes from '../actions/badges'

const initialState = {
  isLoading: false,
  awards: []
}

export const badges = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_BADGES_SUCCESS:
      const awards = action.response.awards

      return {
        ...state,
        awards,
        isLoading: false
      }

    case ActionTypes.LOAD_BADGES_FAILURE:
      return {
        ...state,
        isLoading: false
      }

    case ActionTypes.LOAD_BADGES_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    default:
      return state
  }
}
