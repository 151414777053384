import styled from 'styled-components'
import { FONTS, COLORS, Title5, RegularText, PrimaryLink } from '@ulule/owl-kit-components'

export const SubtleText = styled.span`
  font-size: 13px;
  color: #999;
  font-family: ${FONTS.PRIMARY_FONT};
`

export const TabsContainer = styled.div`
  width: 100%;

  a {
    color: ${COLORS.PRIMARY_BLACK};
    text-decoration: none;
  }
`

export const AdminButton = styled.div`
  position: absolute;
  right: 20px;
`

export const ModalTitle = styled(Title5)`
  text-align: center;
  margin-bottom: 15px;
`

export const ModalContent = styled.div`
  p {
    text-align: center;
  }
`

export const ModalActions = styled.div`
  margin-top: 30px;
`

export const ModalText = styled(RegularText)`
  display: block;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 20px;
`

export const CloseAction = styled(PrimaryLink)`
  display: block;
  font-size: 13px;
  line-height: 16px;
  color: ${COLORS.PRIMARY_BLACK};
  text-decoration: none;
  text-align: center;
  margin-top: 20px;
`
