import React from 'react'

import * as plume from '@ulule/owl-kit-components/next'
import * as S from '../styles'

type InformationProps = {
  label: {
    color: plume.RoleTagProps['backgroundColor']
    name: string
  }
  owner?: {
    fullname: string
  }
}

export function Information({ owner, label }: InformationProps) {
  return (
    <S.UserInfromation>
      <S.UserInformationName>{owner?.fullname}</S.UserInformationName>
      {!!label && <plume.RoleTag backgroundColor={label.color}>{label.name}</plume.RoleTag>}
    </S.UserInfromation>
  )
}

