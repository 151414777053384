import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SponsorshipTracker from '$components/SponsorshipTracker/SponsorshipTracker'
import { userShape } from '$utils/prop-types'

const Avatar = ({ pictureUrl, innerRef }) => <img className="b-user__avatar" src={pictureUrl} ref={innerRef} />

const PartnerAvatar = ({ pictureUrl, userId }) => (
  <SponsorshipTracker position="partner-profile" userId={userId} clickEventConfig={{ enable: false }}>
    <Avatar pictureUrl={pictureUrl} />
  </SponsorshipTracker>
)

const ProfileAvatar = ({ profile, userId, successAvatar, getNewAvatar }) => {
  const avatar = profile && profile.getAvatar && profile.getAvatar('180x180')
  const pictureUrl = successAvatar ? getNewAvatar('180x180') : avatar
  const isPartner = profile && profile.labels && !!profile.labels['official']

  if (isPartner) {
    return <PartnerAvatar pictureUrl={pictureUrl} userId={userId} />
  }

  return <Avatar pictureUrl={pictureUrl} />
}

ProfileAvatar.propTypes = {
  getNewAvatar: PropTypes.func,
  profile: userShape,
  successAvatar: PropTypes.bool,
  userId: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  successAvatar: state.profile.successAvatar,
  getNewAvatar: state.profile.getAvatar
})

export default connect(mapStateToProps)(ProfileAvatar)
