import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { tc } from '@ulule/localize'
import { connect } from 'react-redux'

import { Hover } from './Hover'
import { updateCover } from '$redux/actions/profile'
import { userShape } from '$utils/prop-types'

import * as S from '../styles'

class Header extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.props.handleErrors(this.props.error)
    }
  }

  handleCover = event => {
    const { owner, updateCover, isFileSupported } = this.props
    const file = event.target.files[0]

    const isSupported = isFileSupported(file)

    if (isSupported) {
      updateCover(owner.id, event.target.files[0])
    }
  }

  render() {
    const { getNewCover, successCover, isOwner, currentUser, profile } = this.props
    const canEdit = isOwner || (currentUser && currentUser.is_staff)
    const cover = profile && profile.getCover
    const coverUrl = successCover ? getNewCover : cover

    return (
      <React.Fragment>
        {canEdit && (
          <Hover
            cls="b-user__header"
            placeHolder={tc('Change your banner [br] Maximum size: 2Mb', {
              br: <br />
            })}
            handleChange={this.handleCover}
          >
            <S.Banner cover={coverUrl} />
          </Hover>
        )}
        {!canEdit && (
          <S.UserHeader>
            <S.Banner cover={coverUrl} />
          </S.UserHeader>
        )}
      </React.Fragment>
    )
  }
}

Header.propTypes = {
  currentUser: userShape,
  getNewCover: PropTypes.func,
  handleErrors: PropTypes.func.isRequired,
  isFileSupported: PropTypes.func,
  isOwner: PropTypes.bool.isRequired,
  owner: userShape,
  profile: userShape,
  successCover: PropTypes.bool,
  updateCover: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  updateCover(id, file, token) {
    dispatch(updateCover(id, file, token))
  }
})

const mapStateToProps = state => ({
  successCover: state.profile.successCover,
  error: state.profile.error,
  getNewCover: state.profile.getCover
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
