import React from 'react'
import PropTypes from 'prop-types'

import List from './List'

export const Social = ({ links }) => {
  return (
    <List cls="b-user__social">
      {links &&
        links.length > 0 &&
        links.map((link, index) => {
          if (link.is_email) {
            return (
              <li key={index} className="b-user__link--item">
                <span className="b-user__link">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <path
                      fill="#999999"
                      d="M28 3H4a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V7a4 4 0 0 0-4-4zM4 5h24a2 2 0 0 1 1.9 1.5L16 16.3 2.1 6.5A2 2 0 0 1 4 5zm26 20a2.15 2.15 0 0 1-2 2H4a2.15 2.15 0 0 1-2-2V8.9l13.4 9.4a1.42 1.42 0 0 0 .6.2 1.42 1.42 0 0 0 .6-.2L30 8.9z"
                    />
                  </svg>
                </span>
                <a href={`mailto:${link.url}`} target="_blank" rel="nofollow" className="b-user__social--link">
                  {link.url}
                </a>
              </li>
            )
          }
          return (
            <li key={index} className="b-user__link--item">
              <span className="b-user__link">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <path
                    fill="#999999"
                    fillRule="nonzero"
                    d="M11.9045612 18.3381179c-.4837245-.6466837-.35162-1.5630606.2950637-2.0467852.6466837-.4837245 1.5630606-.35162 2.0467852.2950637 1.0165091 1.3589551 2.5731366 2.2094127 4.2658719 2.330643 1.6927353.1212304 3.3546706-.49872 4.5546508-1.6991353l4.3689592-4.3686469c2.2166193-2.2950354 2.1849185-5.94310904-.0712495-8.19927711-2.2561681-2.25616806-5.9042418-2.28786884-8.1841714-.08605179l-2.5150823 2.50045978c-.5727094.56937967-1.4985555.56668041-2.0679352-.00602896-.5693796-.57270938-.5666804-1.49855548.006029-2.06793515l2.5301881-2.51526207c3.4425531-3.324929 8.9146636-3.27737784 12.2989157.10687426 3.3842521 3.3842521 3.4318033 8.85636264.0890619 12.31704034l-4.3865841 4.3865841c-1.7996892 1.8003418-4.2925921 2.7302674-6.8316951 2.5484218-2.5391029-.1818456-4.8740442-1.4575319-6.3988079-3.4959645zm8.1908776-4.6762358c.4837245.6466837.35162 1.5630606-.2950637 2.0467852-.6466837.4837245-1.5630606.35162-2.0467852-.2950637-1.0165091-1.3589551-2.5731366-2.2094127-4.2658719-2.330643-1.6927353-.1212304-3.3546706.49872-4.55465081 1.6991353l-4.36895917 4.3686469c-2.21661934 2.2950354-2.18491857 5.943109.0712495 8.1992771 2.25616807 2.2561681 5.90424178 2.2878688 8.18115248.0890619l2.5004598-2.5004598c.5710469-.5710469 1.4968969-.5710469 2.0679439 0 .5710469.571047.5710469 1.496897 0 2.0679439l-2.5185844 2.5182722c-3.4425531 3.324929-8.91466362 3.2773778-12.29891572-.1068743-3.3842521-3.3842521-3.43180326-8.8563626-.08906187-12.3170403l4.38658412-4.3865841c1.79968916-1.8003418 4.29259207-2.73026737 6.83169507-2.5484218 2.5391029.1818456 4.8740442 1.4575319 6.3988079 3.4959645z"
                  />
                </svg>
              </span>
              <a className="b-user__social--link" target="_blank" rel="nofollow" href={link.url}>
                {link.url}
              </a>
            </li>
          )
        })}
    </List>
  )
}

Social.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      followers_count: PropTypes.number,
      id: PropTypes.number,
      url: PropTypes.string.isRequired,
      views_count: PropTypes.number,
    })
  ),
}
