import React, { useState } from 'react'

import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'

import { t } from '@ulule/localize'
import styled from 'styled-components'
import { Icon, COLORS, FONTS } from '@ulule/owl-kit-components'
import { rem } from '$utils/styles'

import { Social } from './Social'
import { Location } from './Locations'
import { Information } from './Information'
import { Hover } from './Hover'
import { Button } from './Button'
import { ButtonAction } from './ButtonAction'
import { Modal } from './Modal'
import ProfileAvatar from './ProfileAvatar'

import { userShape } from '$utils/prop-types'

const TabsItem = styled.div`
  margin-bottom: 10px;
`

const IconContainer = styled.span`
  color: #bdbdbd;
  height: 12px;
`

const IconCloseContainer = styled(IconContainer)`
  top: -1px;
  position: relative;
`

const IconEditContainer = styled(IconContainer)`
  top: -1px;
  position: relative;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`

const ButtonContainerAction = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  a {
    font-family: ${FONTS.PRIMARY_FONT};
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    text-decoration: none;
    margin-left: ${rem(8)};
    margin-right: ${rem(20)};
    color: ${COLORS.PRIMARY_RED};

    &:hover {
      color: ${COLORS.PRIMARY_RED_HOVER};
    }
  }
`

const NavSocial = ({ isOwner, currentUser, owner, isReachable }) => {
  return (
    <div className="b-user__modify">
      {isOwner && currentUser && !currentUser.is_staff && (
        <div>
          <ButtonContainer>
            <Button
              text={t('Modify my profile')}
              svg={
                <IconContainer>
                  <Icon glyph="edit" size={15} />
                </IconContainer>
              }
              url={`/users/${owner && owner.id}/settings/`}
            />
          </ButtonContainer>
        </div>
      )}

      {!!isReachable && currentUser && !currentUser.is_staff && (
        <ButtonContainer>
          <Button
            text={t('Send a message')}
            svg={
              <IconContainer>
                <Icon glyph="letter" size={15} />
              </IconContainer>
            }
            url={`/discussions/compose/${owner && owner.id}`}
          />
        </ButtonContainer>
      )}

      {currentUser && currentUser.is_authenticated && currentUser.is_staff && (
        <React.Fragment>
          <TabsItem>
            <ButtonContainer>
              <Button
                text={t('Send a message')}
                svg={
                  <IconContainer>
                    <Icon glyph="letter" size={14} />
                  </IconContainer>
                }
                url={`/discussions/compose/${owner && owner.id}`}
              />
            </ButtonContainer>
          </TabsItem>
          <TabsItem>
            <ButtonContainer>
              <Button
                text={t('Modify my profile')}
                svg={
                  <IconEditContainer>
                    <Icon glyph="edit" size={15} />
                  </IconEditContainer>
                }
                url={`/users/${owner && owner.id}/settings/`}
              />
            </ButtonContainer>
          </TabsItem>
        </React.Fragment>
      )}
    </div>
  )
}

const NavStaff = ({ owner, currentUser, handleBanUser, isBanned }) => {
  const [modalIsOpen, setModalOpen] = useState(false)
  const handleClickBan = () => {
    handleBanUser(owner.id)
  }
  return (
    <div>
      {currentUser.is_staff && (
        <div>
          <ButtonContainerAction>
            <ButtonAction
              text={t('Ban this user')}
              svg={
                <IconCloseContainer>
                  <Icon glyph="close-circle" size={15} />
                </IconCloseContainer>
              }
              url="#"
              action={event => {
                setModalOpen(true)
              }}
            />
          </ButtonContainerAction>
          <Modal
            isBanned={isBanned}
            action={handleClickBan}
            isOpen={modalIsOpen}
            handleClose={() => {
              setModalOpen(false)
            }}
          />
        </div>
      )}
    </div>
  )
}

export const Sidebar = ({ links, lang, owner, handleChange, canEdit, ...props }) => {
  return (
    <aside className="b-user__infos">
      <MediaQuery query="(max-width: 671px)">
        <div className="b-user__mobile">
          {canEdit && (
            <Hover cls="b-user__picture" handleChange={handleChange} placeHolder={t('Change your profile picture')}>
              <ProfileAvatar profile={owner && owner.profile} userId={owner && owner.id} />
            </Hover>
          )}
          {!canEdit && <ProfileAvatar profile={owner && owner.profile} userId={owner && owner.id} />}
          <Information owner={owner} label={owner && owner.profile && owner.profile.getLabel} />
        </div>
      </MediaQuery>

      <MediaQuery query="(min-width: 671px)">
        <Information owner={owner} label={owner && owner.profile && owner.profile.getLabel} />
      </MediaQuery>

      <NavSocial {...props} owner={owner} lang={lang} />

      <NavStaff {...props} owner={owner} lang={lang} />

      <Location profile={owner && owner.profile} owner={owner} />

      {((owner && owner.profile && owner.profile.description && owner.profile.description[lang]) ||
        (links && links.length > 0)) && <hr />}

      <p
        className="b-user__desc"
        dangerouslySetInnerHTML={{
          __html: owner && owner.profile && owner.profile.description && owner.profile.description[lang]
        }}
      />

      <Social links={links} />
    </aside>
  )
}

Sidebar.propTypes = {
  canEdit: PropTypes.bool,
  children: PropTypes.object,
  currentUser: userShape,
  handleChange: PropTypes.func,
  hasBadges: PropTypes.bool,
  isOwner: PropTypes.bool,
  isReachable: PropTypes.bool,
  lang: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      followers_count: PropTypes.number,
      id: PropTypes.number,
      url: PropTypes.string.isRequired,
      views_count: PropTypes.number
    })
  ),
  owner: userShape,
  profile: userShape,
  modalIsOpen: PropTypes.bool,
  setModalIsOpen: PropTypes.func,
  handleBanUser: PropTypes.func,
  isBanned: PropTypes.bool
}
