import React from 'react'
import PropTypes from 'prop-types'

export default class List extends React.Component {
  render() {
    return <ul className={this.props.cls}>{this.props.children}</ul>
  }
}

List.propTypes = {
  children: PropTypes.any,
  cls: PropTypes.string
}
