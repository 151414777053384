import React from 'react'

import PropTypes from 'prop-types'

import { t, tc } from '@owl-nest/localize'
import { ClassicModal, CallToAction } from '@ulule/owl-kit-components'

import * as S from './styles'

export const Modal = ({ isOpen, handleClose, action, isBanned }) => (
  <React.Fragment>
    <ClassicModal overlay isOpen={isOpen} isClosable onClosing={handleClose}>
      <div>
        {!isBanned && <S.ModalTitle>{t('Ban this user')}</S.ModalTitle>}
        {isBanned && <S.ModalTitle>{t('User Banned')}</S.ModalTitle>}
        <S.ModalContent>
          {!isBanned ? (
            <S.ModalText>
              {tc(
                `Be careful, you will ban this user permanently. This action is irreversible.[br]
                The following actions will happen:[br][br]
                - Blocking the user account[br]
                - Deletion of all comments posted by this account[br][br]
                The user will not be informed and will no longer be able to connect to his Ulule account.`,
                {
                  br: <br />
                }
              )}
            </S.ModalText>
          ) : (
            <S.ModalText>
              {tc(`This user has been banned.`, {
                br: <br />
              })}
            </S.ModalText>
          )}
        </S.ModalContent>
        <S.ModalActions>
          {!isBanned ? (
            <React.Fragment>
              <CallToAction onClick={action}>{t('Confirm ban')}</CallToAction>
              <S.CloseAction onClick={handleClose}>{t('Cancel')}</S.CloseAction>
            </React.Fragment>
          ) : (
            <CallToAction
              onClick={() => {
                document.location.reload(false)
              }}
            >
              {t('Close')}
            </CallToAction>
          )}
        </S.ModalActions>
      </div>
    </ClassicModal>
  </React.Fragment>
)

Modal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  action: PropTypes.func,
  isBanned: PropTypes.bool
}
